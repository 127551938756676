/** @format */

import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Link from '@material-ui/core/Link';
import FooterStyles from './styles';
import {useSnackbar} from 'notistack';

const Footer = ({userData}) => {
  const pgStyles = FooterStyles();
  const [email, setEmail] = useState('');
  const {enqueueSnackbar} = useSnackbar();

  const subscribeEmail = () => {
    if (email) {
      const body = {
        email,
      };
      fetch('/api/auth/subscribe', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(resp => {
          if (!resp.ok) {
            throw resp;
          }
          return resp.json();
        })
        .then(user => {
          enqueueSnackbar('Thank you for subscribing.', {variant: 'info'});
          setEmail('');
        })
        .catch(e => {
          enqueueSnackbar('Please enter a valid email.', {variant: 'error'});
          return e;
        });
    } else {
      enqueueSnackbar('Please enter a valid email.', {variant: 'error'});
    }
  };
  return (
    <div className={pgStyles.footerContainer}>
      <div className={pgStyles.footerSection} id="footer-top">
        <img
          alt=""
          src="https://cdn.sunsh1n3.com/Images/logos/pb-white-2023.svg"
          style={{
            width: '15rem',
          }}
        />
        {/* <Typography variant="h4" className={pgStyles.printBedTM}>
          PrintBed™
        </Typography> */}

        <div className={pgStyles.buttonGroup}>
          <IconButton
            className={pgStyles.socialButton}
            href="https://www.facebook.com/printbed/"
            target="_blank"
            rel="noopener noreferrer">
            <FacebookIcon />
          </IconButton>
          <IconButton
            className={pgStyles.socialButton}
            href="https://www.twitter.com/printbed"
            target="_blank"
            rel="noopener noreferrer">
            <TwitterIcon />
          </IconButton>
          <IconButton
            className={pgStyles.socialButton}
            href="https://www.instagram.com/print_bed"
            target="_blank"
            rel="noopener noreferrer">
            <InstagramIcon />
          </IconButton>
          {/* <IconButton
            href='https://www.pinterest.com'
            target='_blank'
            rel='noopener noreferrer'
            className={pgStyles.socialButton}
          >
            <PinterestIcon />
          </IconButton>*/}
          <IconButton
            href="https://www.youtube.com/channel/UCBsoiOZE9kGJU6W5E6SxBDA"
            target="_blank"
            rel="noopener noreferrer"
            className={pgStyles.socialButton}>
            <YouTubeIcon />
          </IconButton>
          <IconButton
            href="https://discord.gg/XqsxbkqPQM"
            target="_blank"
            rel="noopener noreferrer"
            className={pgStyles.socialButton}>
            <img src="/icons/Discord_icon.svg" width="21px" alt="discord" />
          </IconButton>
        </div>

        <div className={pgStyles.subscribeDiv}>
          <Typography
            variant="caption"
            className={pgStyles.greyText}
            id="subscribe-txt">
            Sign up to our newsletter to get coupons in your email:{' '}
          </Typography>
          <Input
            className={pgStyles.emailInput}
            placeholder="Enter your email"
            disableUnderline
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <Button
            variant="outlined"
            className={pgStyles.subscribeButton}
            onClick={subscribeEmail}>
            Subscribe
          </Button>
        </div>
      </div>

      <Divider flexItem className={pgStyles.divider} />

      <div className={pgStyles.categories}>
        <div className={pgStyles.category}>
          <Typography variant="body1" className="title">
            Pages
          </Typography>
          <Typography variant="body2" className={pgStyles.greyText}>
            {/* <Link href="/cardsv2/" color="inherit">
              Promotions
            </Link> */}
            <Link href="/premium/" color="inherit">
              Premium
            </Link>
            <Link href="/shop/" color="inherit">
              Store
            </Link>
            {/* <Link href="/features" color="inherit">
              Features
            </Link>
            <Link href="/shipping" color="inherit">
              Overnight
            </Link>
            <Link href="/blog" color="inherit">
              Blog
            </Link> */}
            <Link href="/help/" color="inherit">
              Support
            </Link>
          </Typography>
        </div>
        <div className={pgStyles.category}>
          <Typography variant="body1" className="title">
            Info
          </Typography>
          <Typography variant="body2" className={pgStyles.greyText}>
            <Link href="/privacy/" color="inherit">
              Privacy Policy
            </Link>
            <Link href="/tos/" color="inherit">
              Terms of Service
            </Link>
            {userData.loggedIn && (
              <>
                <Link href="/account/orders/" color="inherit">
                  Order Tracking
                </Link>
              </>
            )}
          </Typography>
        </div>
        <div className={pgStyles.category}>
          <Typography variant="body1" className="title">
            Contact us
          </Typography>

          <Typography variant="body2" className={pgStyles.greyText}>
            <Link href="mailto:support@printbed.com" color="inherit">
              support@printbed.com
            </Link>
            <Link href="https://maps.app.goo.gl/8Fo4QGu1QXMhecv16" color="inherit" target="_blank">
              14920 23 Mile Rd,
              <br />
              Shelby Township, MI 48315
            </Link>
            <Link href="tel:18334452600" color="inherit">
              1 (833) 445-2600
            </Link>
          </Typography>
        </div>
        <div className={pgStyles.category}>
          <Typography variant="body1" className="title">
            Our Stores
          </Typography>
          <Typography variant="body2" className={pgStyles.greyText}>
            <Link href="https://maps.app.goo.gl/8Fo4QGu1QXMhecv16" color="inherit" target="_blank">
              Detroit
            </Link>
          </Typography>
        </div>

        {/*<div className={pgStyles.category}>
          <Typography variant='body1' className='title'>
            Shop Info
          </Typography>
          <Typography variant='body2' className={pgStyles.greyText}>
            <Link href='#' color='inherit'>
              Our Story
            </Link>
            <Link href='#' color='inherit'>
              Contact us
            </Link>
            <Link href='#' color='inherit'>
              Policy
            </Link>
            <Link href='#' color='inherit'>
              Careers
            </Link>
          </Typography>
        </div>
        <div className={pgStyles.category}>
          <Typography variant='body1' className='title'>
            Shop by
          </Typography>
          <Typography variant='body2' className={pgStyles.greyText}>
            <Link href='#' color='inherit'>
              New Arrivals
            </Link>
            <Link href='#' color='inherit'>
              Best Sellers
            </Link>
            <Link href='#' color='inherit'>
              Featured Products
            </Link>
            <Link href='#' color='inherit'>
              On Sale
            </Link>
          </Typography>
        </div> */}
      </div>

      <Divider flexItem className={pgStyles.divider} />

      <div className={pgStyles.footerSection} id="copyright-txt">
        <Typography variant="caption" className={pgStyles.copyrightText}>
          PrintBed © {new Date().getFullYear()}. All Rights Reserved
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
