/** @format */

import React, {useEffect, useState} from 'react';
import {NavLink, Link, useHistory} from 'react-router-dom';
// Material UI Components
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import ToolBar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail.js'
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
// Custom Components & Styles
import Cart from '../../components/cart';
import UserAccountDropdown from '../../components/userAccount/dropdown.js';
import HeaderStyles from './styles.js';
import Breadcrumbs from './breadcrumb.js';

const Header = props => {
  const pgStyles = HeaderStyles();
  const {cartData, cartUtils} = props;
  const userData = props.userData || {};
  const [isCartShowing, setIsCartShowing] = useState(false);
  // const [scrollPosition,setScrollPosition] = useState(-200);
  const history = useHistory();
  const urlQuery = new URLSearchParams(history.location.search);

  const openAccountModal = event => {
    if (history.location.pathname === '/') {
      history.push('/modal/login');
    } else if (!history.location.pathname.includes('modal')) {
      history.replace({
        pathname: history.location.pathname + '/modal/login',
        search: history.location.search,
      });
    }
  };

  useEffect(()=>{
    if (process.env.NODE_ENV === 'production') {
      try{
        if(isCartShowing) {
          const {grandTotal=0} = cartUtils?.getTotals(cartData).grandTotal
            window.gtag("event", "view_cart", {
              currency:"USD",
              value:Number(grandTotal),
              items:cartData.map((item=>{
                  return { item_id: item.itmeId,
                    item_name:item.name,
                    item_category:item.options.material,
                    price:Number(item.price),
                    quantity:item.qty}
              }))
            });
        }
      } catch(e) {}
    }
  },[isCartShowing])

  const ToolbarButton = props => {
    return (
      <Button
        className={pgStyles.toolbarButton}
        disableRipple
        {...props.btnProps}>
        {props.label}
      </Button>
    );
  };

  if (urlQuery.has('rid')) {
    if (!history.location.pathname.includes('modal')) {
      history.replace({
        pathname: '/modal' + history.location.pathname,
        search: history.location.search,
      });
    }
  }

  return (
    <AppBar className={pgStyles.header} position="sticky">
      <ToolBar className={pgStyles.toolbar}>
        <Link to="/" className={pgStyles.printBedLogo}>
          <img
            src="https://cdn.sunsh1n3.com/Images/logos/pb-half-2023.svg"
            alt="PrintBed logo"
            className="logo"
          />
          {userData.membership && (
            <img
              src={'https://cdn.sunsh1n3.com/Images/logos/pb-premium.webp?h=60'}
              alt="PrintBed logo"
              className="logo"
              style={{padding: 20}}
            />
          )}
        </Link>
        <ToolBar className={pgStyles.navLinks}>
          <NavLink
            to="/shop/"
            className={pgStyles.navLink}
            activeClassName="active shop">
            <ToolbarButton label="Store" />
          </NavLink>
          <NavLink
            to="/premium/"
            className={pgStyles.navLink}
            activeClassName="active premium">
            <ToolbarButton label="Premium" />
          </NavLink>
          <NavLink
            to="/process/"
            className={pgStyles.navLink}
            activeClassName="active elements">
            <ToolbarButton label="Process" />
          </NavLink>
          <NavLink
            to="/blog/"
            className={pgStyles.navLink}
            activeClassName="active blog">
            <ToolbarButton label="Blog" />
          </NavLink>
          <NavLink
            to="/help/"
            className={pgStyles.navLink}
            activeClassName="active pages">
            <ToolbarButton label="Help" />
          </NavLink>
          <NavLink
            to="/about/"
            className={pgStyles.navLink}
            activeClassName="active about">
            <ToolbarButton label="About" />
          </NavLink>
        </ToolBar>
        <ToolBar className={pgStyles.navLinks}>
            <Button
              className={pgStyles.toolbarButton}
              disableRipple
              href="callto:18334452600">
              <PhoneIcon />
            </Button>
            <Button
              className={pgStyles.toolbarButton}
              disableRipple
              href="mailto:support@printbed.com">
              <MailIcon />
            </Button>
          <UserAccountDropdown userData={userData} />
          {!userData.loggedIn && (
            <Button
              className={pgStyles.toolbarButton}
              disableRipple
              onClick={openAccountModal}>
              Sign In
            </Button>
          )}
          <Button href="/shop#search" className={pgStyles.toolbarIcon}>
            <SearchIcon />
          </Button>
          <Button
            className={pgStyles.toolbarIcon}
            onClick={() => setIsCartShowing(!isCartShowing)}>
            <Badge
              badgeContent={cartData.length}
              classes={{badge: pgStyles.cartBadge}}
              color="primary"
              overlap="rectangular">
              <img src="/icons/shopicon.svg" alt="shop icon" />
            </Badge>
          </Button>
          <Drawer
            anchor="right"
            classes={{paper: pgStyles.cartContainer}}
            open={isCartShowing}
            onClose={() => setIsCartShowing(false)}
            ModalProps={{
              keepMounted: true,
              disablePortal: true,
            }}>
            <Cart
              hasCheckoutButton
              close={() => setIsCartShowing(false)}
              cartData={cartData}
              cartUtils={cartUtils}
            />
          </Drawer>
        </ToolBar>
      </ToolBar>
      <Breadcrumbs />
    </AppBar>
  );
};

export default Header;
