/** @format */

import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// Custom Components
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import {useLocation} from 'react-router-dom';
import ColorPicker from '../../components/colorpicker';
import './upload.css';
import {useSnackbar} from 'notistack';
import { Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getColor } from './slicer/utils.js';
import Viewer  from './slicer/index.js';

const POD_ITEM = "6697e08fca89cda62f4c8298";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width:'100%',
    height:'calc(100vh - 80px)',
    backgroundColor:'#EFEFEF'
  },
  button: {
    marginBottom:'2rem'
  },
  paper: {
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    display:'flex',
    alignItems:'baseline',
    justifyContent:'space-between',
    marginBottom:'2rem'
  },
  paperSummary: {
    backgroundColor:'white',
    padding:'1rem 2rem',
    borderRadius:'2rem',
    // display:'flex',
  },
  uploadGrid: {
    display:'flex',
    marginTop: 50,
    justifyContent:'center'
  },
  uploader:{
    backgroundColor:'white',
    height:400,
    padding: '5rem',
    textAlign:'center',
    width:'50%',
    borderRadius:'2rem'
  },
  control: {
    padding: theme.spacing(2),
  },
  settings: {
    marginTop: '3rem',
    display:'flex',
    flexDirection:'column',
    overflowY:'scroll'
    // justifyContent:'space-between'
  },
  formControl:{
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    textAlign:'center',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 200,
    '& input':{
      textAlign:'center'
    }
  },
  InputAdornment:{
    '& .MuiTypography-colorTextSecondary':{
      color:'black'
    }
  }
}));

let pInfill = 20;

const PodPage = props => {
  const {cartUtils, isMobile} = props;
  const pondRef = useRef(null);
  const [materials, setMaterials] = useState([]);
  const [material,setMaterial] = useState("PLA");
  const [infill,setInfill] = useState(20);
  const [color,setColor] = useState('black');
  const [qty,setQty] = useState(1);
  const [files, setFiles] = useState(null);
  const [file,setFile] = useState();
  const [modelData,setModelData] = useState({});
  const [isVase,setIsVase] = useState(false);
  const [preview,setPreview] = useState();
  const [colorMap,setColorMap] = useState({});
  const [item,setItem] = useState({price:0})
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  
  const defaultTags = {
    color: 'Black',
    material: 'PLA',
    weight: '1kg',
    diameter: '1.75mm',
    colorName: 'Black',
  };


  useEffect(() => {
    getPODItem();
    fetch(`/api/items/tags?q=tags.material=PLA,PLA Ltd,FORGE,PETG,ABS%26tags.weight=1kg`)
      .then(resp => resp.json())
      .then(data => {
        let tmp = new Set();
        let tmpCM = {}
        data.forEach(item=>{
          tmp.add(item.tags.material);
          if(!tmpCM[item.tags.material]) {
            tmpCM[item.tags.material] = new Set();
          }
          tmpCM[item.tags.material].add(item.tags.color.toTitleCase());
        });

        setMaterials([...tmp]);
        Object.keys(tmpCM).forEach(key=>{
          tmpCM[key] = Array.from(tmpCM[key]);
        });
        setColorMap(tmpCM);
        setColor(getRandomItem(tmpCM["PLA"]));
      });
    // eslint-disable-next-line
  }, []);
  const handleChange = (event) => {
    setMaterial(event.target.value);
  };

  const getPODItem = () => {
    fetch(`/api/item/6697e08fca89cda62f4c8298`).then(resp => resp.json())
    .then(data => {
      console.log(data)
      setItem(data);
    });
  }

  const getDensity = () => {
    let density;
    switch(material) {
      case 'PETG': 
        density = 1.27;
      break;
      case 'ABS': 
        density = 1.07;
      break
      default:
        density = 1.25;
        break;
    }
      return density;
  }

  useEffect(()=>{
    if(colorMap[material]) {
      setColor(getRandomItem(colorMap[material]));
    }
  },[material]);

  const colorChange = (e) => {
    setColor(e.target.value);
  }

  const previewLoaded = (preview) => {
    // let previewUrl = `/api/file/${preview.id}/preview`
    setPreview(preview);
  }

  const getVolume = (data={}) => {
    const {modelVolume=0,surfaceAreaVolume=0} = data;
    let V_effective = (modelVolume / 1000) * (infill/100);

    if(isVase) {
      V_effective = surfaceAreaVolume;
    }

    // Calculate the mass in grams
    const mass = V_effective * getDensity();
    return mass.toFixed(2);
  }

  const reset = () => {
    setFile(null);
    setFiles([]);
    setPreview("");
    // setInfill(20);
  }

  const setVaseMode = (e) => {
    const checked = e.target.checked;
    if(checked) {
      pInfill = infill;
      setInfill(0);
    } else {
      setInfill(pInfill);
    }
    setIsVase(checked);
  }

  const handleAddFile = (error, file) => {
    if (error) {
      console.log('Error adding file:', error);
      return;
    }
    setFile(file.file);
  };

  const addToCart = () => {
    if (pondRef.current) {
      pondRef.current.processFiles(); // Trigger the file upload manually
    }
  }

  const addToCartStep2 = async (fileId) => {
    await uploadPreview(fileId);
    const previewUrl = `/api/file/${fileId}/preview`;
    const {subTotal} =  getPrice(modelData);
    const data = {type:'pod', id:POD_ITEM, quantity:qty, options:{material:material,color:color,fileId:fileId,preview:previewUrl, price:subTotal.toFixed(2)}}
    // console.log(data);
    cartUtils.addItemToCart(data);
     
  }

  const uploadPreview = (fileId) =>{
    let data = {
      preview: {
      type: 'img',
      value: preview
      }
  };

  return fetch(`/api/file/${fileId}`, {
      method: 'put',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
  }).then(res => res.json()).then(data => {
      return data;
  }).catch(e => {
  });
  }

  const getPrice = (modelData={}) => {
    const {modelVolume} = modelData;
    const data = {itemPrice: 0,subTotal:0}
    if(modelVolume){
      data.itemPrice = getVolume(modelData)*(item.price/100);
      data.subTotal = data.itemPrice * qty;
    } 
    return data;
  }

  useEffect(()=>{
    if(modelData.oversized === true) {
      enqueueSnackbar('This model is too loarge for us to print.', {variant:'error'});
    }
  },[modelData])

  return (
    <>
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={9} className={classes.uploadGrid}>
        {file?<Viewer file={file} color={color} previewLoaded={previewLoaded} onLoaded={setModelData}/>:null}
        <Paper className={classes.uploader} elevation={0} style={{visibility:file?'hidden':'visible', position:file?'absolute':'inherit'}}>
          <Typography variant='h3' color="primary">Upload your file</Typography>
          <Typography variant='body1'>Max file size is 100mb</Typography>
          <br style={{clear:'both'}}/>
          <br/>
          <FilePond
            files={files}
            onupdatefiles={setFiles}
            allowMultiple={false}
            maxFiles={1}
            server={{
              url:"/api/file",
              process:{
                onload: (resp)=>{
                  let data = JSON.parse(resp);
                  return data.id;
                }
              }
            }}
            instantUpload={false}
            name="upfile"
            onaddfile={handleAddFile}
            ref={pondRef}
            allowProcess={true}
            acceptedFileTypes={['.stl']}
            credits=""
            onprocessfile={(err,resp)=>{
              if(err) {
                console.error(err);
              }
              addToCartStep2(resp.serverId);
            }}
            labelIdle='<img src="/icons/upload.svg" alt="upload"/><br/>Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
          </Paper>
      </Grid>

      <Grid item xs={12} md={3} className={classes.settings}>
        <Paper className={classes.paperSummary}>
          <Typography variant='body1'>Model</Typography>
          <Typography variant='body1'>Size: {modelData.modelSize?.width.toFixed(2)}mm x {modelData.modelSize?.height.toFixed(2)}mm x {modelData.modelSize?.depth.toFixed(2)}mm</Typography>
          <Typography variant='body1'>Material: {getVolume(modelData)}g</Typography>
        </Paper>
        <div style={{height:"2rem"}}></div>
        <Paper className={classes.paper}>
          <Typography variant='body1'>MATERIAL</Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={material}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {materials.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
        <Paper className={classes.paperSummary} elevation={0}>
          <div style={{flex: '1 0 100%'}}><Typography variant='body1'>COLOR</Typography></div>
          <div style={{flex: '1 0 100%'}}>
          {colorMap[material]?
          <ColorPicker 
          onChange={colorChange}
          color={color}
          colors={colorMap[material]}
          />:null}</div>
        </Paper>
        <div style={{height:"2rem"}}></div>
        <Paper className={classes.paper}>
          <Typography variant='body1'>INFILL</Typography>
          <FormControl className={classes.textField}>
            <TextField
              id="infill"
              value={infill}
              type="number"
              min="0" 
              max="100"
              onChange={(e,val)=>{
                let tmp = Number(e.target.value);
                tmp = tmp>100?100:tmp;
                setInfill(tmp)
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end" className={classes.InputAdornment}>%</InputAdornment>,
              }}
            />
          </FormControl>
        </Paper>
        <Paper className={classes.paper}>
            <Typography variant='body1'>QUANTITY</Typography>
            <div style={{display:'flex', alignItems:'center', }}>
              <IconButton
                disableRipple
                className="qty-btn remove"
                disabled={qty <= 1}
                onClick={e => {setQty(tmp=>tmp-1)}}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography id="qty-txt">{qty}</Typography>
              <IconButton
                disableRipple
                className="qty-btn add"
                onClick={e => {setQty(tmp=>tmp+1)}}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
        </Paper>
        <Paper className={classes.paper} style={{flexWrap:'wrap'}}>
            <Typography variant='body1'style={{flex:'1 0 80%'}}>Print as Vase<sup>*</sup></Typography>
            <Checkbox
              checked={isVase}
              color="primary"
              style={{color:'black',flex:'1'}}
              onChange={setVaseMode}
              inputProps={{ 'aria-label': 'Suports' }}
            />
            <Typography variant='caption' ><sup>*</sup>Hollow and no top layer</Typography>
        </Paper>
        {/*
        {supports && <Paper className={classes.paper}>
            <Typography variant='body1'>Support Type</Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              value={material}
              onChange={handleChange}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {materials.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>} */}
        <Paper className={classes.paperSummary}>
            {preview &&
              <img src={preview} style={{float:'left', padding: '3rem'}} height="200" alt="preview" />
            }
            <div style={{ padding: '3rem 0'}}>
            <Typography variant='body2'>/EA</Typography>
            <Typography variant='body2'>${getPrice(modelData).itemPrice.toFixed(2)}</Typography>
            <br/>
            <Typography variant='body1'>TOTAL</Typography>
            <Typography variant='body1' color="primary">${getPrice(modelData).subTotal.toFixed(2)}</Typography>
            </div>
            <br/>
            <br/>
            <Button
              className={classes.button}
              // disabled={!(currentItem.qoh > 0)}
              // endIcon={<ShoppingCart size={16} />}
              fullWidth
              color="default"
              onClick={reset}
              variant="contained">
              Cancel
            </Button>
            <Button
              className={classes.button}
              disabled={!modelData.modelVolume || modelData.oversized === true}
              // endIcon={<ShoppingCart size={16} />}
              fullWidth
              color="primary"
              onClick={addToCart}
              variant="contained">
              Add to Cart
            </Button>
        </Paper>
      </Grid>
    </Grid>
    </>
  );
};

function getRandomItem(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export default PodPage;
