/** @format */

import React, {useState} from 'react';
// npm package imports
import parse from 'html-react-parser';
// Material UI Components
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
// Material UI Icons
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
// Custom Components
import {SunValidatedInput} from '@sunsh1n3/sun-validated-input';
// Styles
import FaqStyles from './styles';

const StyledBase = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 'none',
    '&.Mui-expanded': {
      margin: 0,
      marginBottom: '7.5rem',
    },
  },
  expanded: {
    marginBottom: '7.5rem ',
  },
})(Accordion);

const StyledSummary = withStyles({
  root: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
})(AccordionSummary);

function StyledAccordion(props) {
  const pgStyles = FaqStyles();
  const {title, text, isMobile} = props;
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <StyledBase
      className={isMobile?pgStyles.styledAccMobile:pgStyles.styledAcc}
      elevation={0}
      onChange={e => setIsExpanded(!isExpanded)}
      {...props}
    >
      <StyledSummary expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}>
        <Typography align="left" variant="body1">
          {title}
        </Typography>
      </StyledSummary>
      <AccordionDetails>
        <Typography variant="body1" className="qtxt" component="div">
          {parse(text)}
        </Typography>
      </AccordionDetails>
    </StyledBase>
  );
}
const QUESTIONS = [
  {
    title: 'Are all your products made in the USA using ethical labor?',
    text: '<p>Absolutely.  We source everything <a href="/process">from the USA</a>, from the plastics to the boxes.  We have a small staff that makes the products and fulfills your orders here in Michigan.</p>',
  },
  {
    title: 'If I purchase on a weekend will it arrive the next day?',
    text: '<p>Unfortunately not.  We do not ship on the weekends. We are a small staff and we take the weekends off. Your order will ship first thing the next business day.</p>',
  },
  {
    title: 'How secure is my payment information?',
    text: '<p>We use Stripe as our payment processor which is used by many web retailers.  You can learn more about Stripe security <a href="https://stripe.com/docs/security/stripe" target="_blank">here.</a></p>',
  },
  {
    title: 'Do you offer overnight shipping?',
    text: '<p>Yes, all our orders ship overnight for just $10. This is to the continental U.S.</p>',
  },
  {
    title: 'When will my subscription ship?',
    text: '<p>Your <a href="/premium/">Premium subscription</a> will ship within 1 business day of placing your initial order.  Your next month\'s roll will ship on your next billing date or on the following business day if it\'s on the weekend or holiday.</p>',
  },
  {
    title: 'How do I cancel my subscription?',
    text: '<p>You can click on your avatar in the top right after you login and click on profile. Go to the <a href="/account/subs">subscriptions</a> tab and click more info. Click on cancel my subscription.</p>',
  },
  {
    title:
      'I have a problem with my order. How do I contact you to get it corrected?',
    text: '<p>Please send us an email at <a href="mailto:support@printbed.com">support@printbed.com</a> with your order number and we will handle the issue as quickly as possible.</p>',
  },
  {
    title:
      'If I buy the same color filament in a year, will it match the color I have now?',
    text: '<p>Our primary focus is on high-quality 3D printing spools so we ensure the colors of filaments always stay the same, no matter when you place an order. </p>',
  },
  {
    title:
      'How is Forge PLA+ different from standard PLA?',
    text: '<p>Forge PLA+ is stronger and more heat resistant than conventional PLA so it may need a slightly higher heating temperature. Forge PLA+ can also be heated and allowed to cool slowly to increase its strength and make it more durable and workable.</p>',
  },
  {
  title: 'Will your filaments work in my printer?',
  text: '<p>Most desktop 3D printers that use Fused Deposition Modeling (FDM) can handle PLA, Forge PLA+, ABS, and PETG, which we offer.</p>',
  },
  {
  title: 'What is the strongest filament you offer?',
  text: '<p>The strongest filament we offer is ABS which is a high-strength and heat-resistant material, making it an excellent choice for printing items such as prototypes. It’s also ideal for high-impact applications like phone cases, tool handles, housings, brackets, mounts, and more.</p>',
  },
  {
  title: 'The Limited Edition PLA that I wanted is out of stock, will it be coming back?',
  text: '<p>Our Limited Edition colors are available for a limited time only, so grab them while you can! Some of these colors may become part of our standard collection based on popular demand. </p>',
  },
  {
  title: 'How should I store my 3D printing filament?',
  text: '<p>The key to preserving your <a href="https://www.printbed.com/">3D printing filament</a> during storage is to keep it dry. It’s therefore best to store filaments in an airtight container in a cool, dry place out of sunlight. This will prevent the material from absorbing moisture from the surrounding air. For long-term storage, vacuum sealing is an excellent choice as it creates an airtight seal around the spool. </p>',
  },
];

export default function FaqComp({isMobile}) {
  const pgStyles = FaqStyles();
  return (
    <div className={pgStyles.wrapper}>
      <div className={pgStyles.banner} style={{height:isMobile?120:''}}>
        <img
          className="banner-img"
          alt="FAQ banner"
          src="/images/faq-banner.png"
        />
        <Typography align="center" className={isMobile?"titlemobile":"title"} variant={"h3"}>
          Hello, how can we help?
        </Typography>
        {/* {!isMobile &&
        <SunValidatedInput
          className="inp"
          placeholder="Ask a question..."
          startAdornment={<SearchIcon style={{color: '#A4AAB8'}} />}
        />} */}
      </div>
      <div className={pgStyles.mainContent} >
        <div className={pgStyles.contentHeader} style={{maxWidth:isMobile?'90%':'80%'}}>
          <Typography align="center" className={isMobile?"title":"titleh1"} variant={"h1"}>
            Frequently Asked Questions
          </Typography>
          <Typography align="center" className="desc" variant="body1" style={{fontSize:isMobile?'6rem':''}}>
            PrintBed is dedicated to helping you find information about our products. We provide a way for you to access quick and easy answers to the FAQs below or you can email us at {' '}
            <a href="mailto:support@printbed.com">support@printbed.com</a>
          </Typography>
        </div>
        <div className={pgStyles.questions} style={{width:isMobile?'90%':'80%'}}>
          {QUESTIONS.map((question, index) => {
            return (
              <div key={index}>
                {index > 0 ? <Divider key={'div' + index} /> : null}
                <StyledAccordion
                  title={question.title}
                  text={question.text}
                  key={'q' + index}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
        </div>
        <div className={pgStyles.qNotFound}>
          {/* <Typography align='center' variant='h4'>
            Didn't find what you are looking for?
          </Typography>
          <Typography align='center' variant='h6'>
            Don't worry, you can chat with us!
          </Typography>
          <Button className='btn'>Connect to Live Chat</Button> */}
        </div>
      </div>
    </div>
  );
}
