import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {getColor, saveImgPreview} from './utils';
let mainMaterial,light, keylight;
const Viewer = (props) => {
  const {file,color,previewLoaded=()=>{},onLoaded=()=>{}} = props;
  const printerVolume = [350,350,350];  //x,z,y
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene
    const scene = new THREE.Scene();
    scene.background = null 
    // Set up the camera
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(55.08251755841068,92.53450974816863,222.67800166430303);
    camera.rotation.set(-0.39384181438036986,0.22457314516465918,-0.09227679038287222);
    // Set up the renderer
    const renderer = new THREE.WebGLRenderer({alpha:true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Set up lighting (optional, but good for STL models)
    light = new THREE.DirectionalLight(0xFFFFFF, 1);
    light.position.copy(camera.position);
    scene.add(light);

    keylight = new THREE.DirectionalLight(0xffffff, 1);
    keylight.position.set(50, 50, 100);
    scene.add(keylight);


    const ambientLight = new THREE.AmbientLight(0xFFFFFF); 
    scene.add(ambientLight);
    ambientLight.position.set(0, 1, 0);

    // Load the STL file
    const loader = new STLLoader();
    const reader = new FileReader();

    reader.onload = function(e) {
        const contents = e.target.result;
        const geometry = loader.parse(contents);
        var hex = parseInt(getColor(color).replace(/^#/, ''), 16);
        // Create a material for the model
        mainMaterial = new THREE.MeshPhysicalMaterial({  
          color: hex, 
          side:THREE.DoubleSide,  
          emissive:0x000000, 
          // specular:0x000000, 
          // shininess: 30,
          // fog:true,
          reflectivity: 1,
          // refractionRatio: 0.9 ,
          ior: 1.5, 
          iridescenceIOR: 1.3,
          specularColor: 0xFFFFFF, 
          reflectivity: 0.9, 
          metalness: 0.5, 
          sheenRoughness: 1,
          roughness:1 
        });

        // Create a mesh and add it to the scene
        const mesh = new THREE.Mesh(geometry, mainMaterial);
        scene.add(mesh);

        mesh.rotation.x = -Math.PI / 2;
         // Compute the bounding box of the geometry
        const boundingBox = new THREE.Box3().setFromObject(mesh);

        // Compute the center of the bounding box
        const center = new THREE.Vector3();
        boundingBox.getCenter(center);

        // Reposition the mesh so that its center aligns with (0, 0, 0)
        mesh.position.sub(center);

        const offset = boundingBox.min.y;
        mesh.position.y = -offset;

        light.target = mesh;
        keylight.target = mesh;
        const modelVolume = computeVolume(geometry);  // Total solid volume of the model (in mm³)

        const modelSize = getDimmensionsFromBoundingBox(boundingBox);
        const surfaceAreaVolume = computeSurfaceArea(geometry);
        const oversized = !(modelSize.width <= printerVolume[0] && modelSize.depth <= printerVolume[1] && modelSize.height <= printerVolume[2]);
        onLoaded({modelVolume,modelSize, surfaceAreaVolume, oversized});
        requestAnimationFrame(() => {
          renderer.render(scene, camera); // Ensure the scene is rendered
          saveImgPreview(renderer.domElement).then(previewLoaded);
          loadPrintbed();
        });
    };

    reader.readAsArrayBuffer(file);

    const loadPrintbed = () => {
      let printbedUrl = `/models/beds/bed.stl`;
      loader.load(printbedUrl,(geometry)=>{
          const material = new THREE.MeshPhongMaterial({ color: 0x000000,side:THREE.FrontSide,   }); // Green material
          const mesh = new THREE.Mesh(geometry, material);
          scene.add(mesh);
          const scaleWidth = printerVolume[0] / 235;
          const scaleLength = printerVolume[1] / 235;
          mesh.scale.set(scaleWidth,scaleLength,1);
          mesh.rotation.x = -Math.PI / 2; // Rotate if needed
          mesh.position.set(0, -1, 0);
          const jsonURL = "/models/logo/logo.json";
          fetch(jsonURL)
          .then(response => response.json())
          .then(json => {
              const objectLoader = new THREE.ObjectLoader();
              const loadedObject = objectLoader.parse(json);
              const [x, y] = printerVolume;
              let posY = y/2 - 15;
              scene.add(loadedObject);
              loadedObject.rotation.x = -Math.PI / 2;
              loadedObject.rotation.y = -Math.PI;
              loadedObject.position.set(x/2 - 15, .1, posY); 
              loadedObject.scale.set(.15,.15,.15);
          }).catch(error => console.error('Error loading JSON object:', error));
      });
    }

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Enable damping (inertia) for smoother controls
    controls.dampingFactor = 0.25; // Adjust damping factor
    const updateLightPosition = () => {
      // Set the light's position to the camera's position
      light.position.copy(camera.position);
      
      // Ensure the light is always pointing towards the object
      // light.target.updateMatrixWorld();
  }
    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      updateLightPosition();
      renderer.render(scene, camera);
      // Inside the animate function

    };
    animate();



    


    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Clean up on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    if(mainMaterial) {
      console.log(color)
        const hexColor = parseInt(getColor(color).replace(/^#/, ''), 16);
        mainMaterial.color.set(hexColor);
    }
  },[color])

  return <div ref={mountRef} />;
};

export default Viewer;

function computeSurfaceArea(geometry) {
  let surfaceArea = 0;
  const position = geometry.attributes.position.array;

  // Iterate through each triangle in the geometry
  for (let i = 0; i < position.length; i += 9) {
      const v0 = new THREE.Vector3(position[i], position[i + 1], position[i + 2]);
      const v1 = new THREE.Vector3(position[i + 3], position[i + 4], position[i + 5]);
      const v2 = new THREE.Vector3(position[i + 6], position[i + 7], position[i + 8]);

      // Compute the area of the triangle using the cross product
      const crossProduct = new THREE.Vector3().crossVectors(
          new THREE.Vector3().subVectors(v1, v0),
          new THREE.Vector3().subVectors(v2, v0)
      );

      // Area of the triangle (1/2 * magnitude of cross product)
      const triangleArea = crossProduct.length() / 2.0;

      // Accumulate the surface area
      surfaceArea += triangleArea;
  }
  const wallThickness = 1.2;  // Wall thickness in mm

  // Convert surface area from mm² to cm² (divide by 100) and wall thickness from mm to cm (divide by 10)
  const volume = (surfaceArea / 100) * (wallThickness / 10);  // Volume in cm³
  return volume; // Surface area in the same units as the model (e.g., mm²)
}




function computeVolume(geometry) {
  let volume = 0;
  const position = geometry.attributes.position.array;

  // Iterate through each triangle in the geometry
  for (let i = 0; i < position.length; i += 9) {
      const v0 = new THREE.Vector3(position[i], position[i + 1], position[i + 2]);
      const v1 = new THREE.Vector3(position[i + 3], position[i + 4], position[i + 5]);
      const v2 = new THREE.Vector3(position[i + 6], position[i + 7], position[i + 8]);

      // Compute cross product and dot product for the volume of the tetrahedron
      const crossProduct = new THREE.Vector3().crossVectors(v1, v2);
      const tetraVolume = v0.dot(crossProduct) / 6.0;

      // Accumulate the absolute value of the volume (since some volumes can be negative)
      volume += Math.abs(tetraVolume);
  }

  return volume; // Volume in the same units as the model (e.g., mm³)
}

function calculateVoxelVolume(mesh, voxelSize) {
  const geometry = mesh.geometry;

  // Compute the bounding box of the geometry
  geometry.computeBoundingBox();
  const bbox = geometry.boundingBox;

  // Get the dimensions of the bounding box
  const min = bbox.min;
  const max = bbox.max;

  // Calculate the number of voxels in each direction
  const sizeX = Math.ceil((max.x - min.x) / voxelSize);
  const sizeY = Math.ceil((max.y - min.y) / voxelSize);
  const sizeZ = Math.ceil((max.z - min.z) / voxelSize);

  let voxelCount = 0;

  // Loop through each voxel
  for (let i = 0; i < sizeX; i++) {
    for (let j = 0; j < sizeY; j++) {
      for (let k = 0; k < sizeZ; k++) {
        // Calculate the center of the current voxel
        const x = min.x + i * voxelSize + voxelSize / 2;
        const y = min.y + j * voxelSize + voxelSize / 2;
        const z = min.z + k * voxelSize + voxelSize / 2;
        const voxelCenter = new THREE.Vector3(x, y, z);

        // Check if this point is inside the mesh using raycasting
        if (isPointInsideMesh(voxelCenter, mesh)) {
          voxelCount++;
        }
      }
    }
  }

  // Return the total volume in mm³ (voxelCount * voxelVolume)
  const voxelVolume = Math.pow(voxelSize, 3);
  return voxelCount * voxelVolume;
}

/**
 * Helper function to check if a point is inside a mesh using raycasting.
 * @param {THREE.Vector3} point - The point to check.
 * @param {THREE.Mesh} mesh - The mesh to test against.
 * @returns {boolean} - True if the point is inside the mesh, false otherwise.
 */
function isPointInsideMesh(point, mesh) {
  const raycaster = new THREE.Raycaster();

  // Cast a ray in an arbitrary direction (e.g., along the positive X-axis)
  const direction = new THREE.Vector3(1, 0, 0);
  raycaster.set(point, direction);

  // Check how many intersections we get
  const intersections = raycaster.intersectObject(mesh, true);

  // If the number of intersections is odd, the point is inside the mesh
  return intersections.length % 2 === 1;
}

function getDimmensionsFromBoundingBox(boundingBox) {
  // Create a Box3 instance and compute the bounding box

  // Get the minimum and maximum points
  const min = boundingBox.min;
  const max = boundingBox.max;

  // Calculate dimensions
  const width = max.x - min.x;
  const height = max.y - min.y;
  const depth = max.z - min.z;

  return { width, height, depth };
}

